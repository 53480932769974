a {
  //color: $vera-labs-gold;
  font-weight: 600;
}

h2 {
  color: #1e1e1e;
  font-size: 1.75rem;
  font-weight: bold;
}

h3 {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 500;
}

li {
  margin-left: 1.25rem;
}

button {
  cursor: pointer;
}

// Spacer Styles
.spacer-xsmall {
  height: .5em;
  font-size: .5rem;
  line-height: .5rem;
}

.spacer-small {
  height: 1em;
  font-size: .65rem;
  line-height: .5rem;
}

.spacer-medium {
  height: 2em;
  line-height: 2em;
}

.spacer-large {
  height: 3.5em;
  line-height: 3.5em;
}

.spacer-xlarge {
  height: 5em;
  line-height: 5em;
}

.width-100-percent {
  width: 100%;
}

.vera-labs-hr-dark {
  border-color: $black;
}

#overlay-wrapper {
  position: relative;
  #overlay {
    content:"";
    position:fixed;
    display:block;
    top:0;
    left:0;
    bottom:0;
    height:100%;
    width:12px;
    background-color:#a31e23;
    z-index:9999
  }
}

#vl-main-wrapper {
  padding: 0;
}

.vl-content-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
  h2, h3 {
    width: 100%;
  }
}

.vera-labs-name {
  font-weight: bold;
  color: $vera-labs-red;
}

.vera-labs-button {
  background-color: $vera-labs-red;
  color: $white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border: 1pt solid $vera-labs-dark-red;
  &:hover {
    background-color: $vera-labs-dark-red;
  }
}

.learn-more-text {
  color: $vera-labs-dark-red;
}

