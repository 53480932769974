.interior-header-logo-wrapper {
  max-width: 60%;
  text-align: center;
  margin: 2rem auto 1rem;
}

.interior-text-header {
  font-size: 1.125rem;
  color: #000000;
}

.interior-text-icon {
  color: #b5000a;
  font-size: 2rem;
}

.bg-image {
  /* image specified in separate class  */
  height: 250px;
  width: 100%; }

.bg-image-banner {
  /* background-image: url('/themes/vera-labs-main/assets/images/alexander-andrews-636454-unsplash-1024x683-1.jpeg'); */
  /* lt ie8 */
  -ms-background-position-x: center;
  -ms-background-position-y: center;
  /* default - may override with classes or media query */
  background-position: center center;
  /* scale bg image proportionately */
  background-size: cover;
  /* ie8 polyfill */
  //-ms-behavior: url(backgroundsize.min.htc);
  /* prevent scaling past src width (or not) */
  /* max-width: 1200px; */
}

.bg-center-center {
  -ms-background-position-x: center;
  -ms-background-position-y: center;
  background-position: center center; }

/* the "padding trick" thanks to Corey Worrell */
.background {
  height:0;
  padding-bottom:40%;
  /* background-image: url('/themes/vera-labs-main/assets/images/alexander-andrews-636454-unsplash-1024x683-1.jpeg'); */
  background-position: center bottom;
  /* scale bg image proportionately */
  background-size: cover; /* or 100% */
}


/* example media query */
@media only screen and (min-width : 768px) {

  .bg-image { height: 250px; }
}

@media only screen and (min-width : 1200px) {

  .bg-image { height: 250px; }
}

@media screen and (max-width: 39.9375em) {
  #team-page-header-text {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  #team-page-header-text {
    font-size: 1.50rem;
  }
}


#vera-brands-quote-banner {
  background-image: url('/themes/vera-labs-main/assets/images/Passion-Matters-Model-with-Red-Background-Screenshot-1.png');
  width: 100%;
  color:white;
  padding: 3rem;
  font-size: 2.125rem;
}

#vera-brands-slogan {
  font-size: 1.75rem;
  padding: 4rem 4rem 3rem;
  border-bottom: 1px solid $black;
}

#vera-brands-break-thru-logo {
  max-width: 304px;
  margin-bottom: 2rem;

}

.vera-brands-podcast-wrapper {
  max-width: 762px;
  margin: 2rem auto 3rem;
}

.vera-brands-video-wrapper {
  margin-top: -7%
}

#vera-brands-passion-matters-logo {
  max-width: 486px;
}

#vera-brands-pm-photos img {
  margin-bottom: 2rem;
}

.team-banner {
  background-image: url('/themes/vera-labs-main/assets/images/SpoonGraphics-Topographic-Map-9.jpg') !important; /* temp - until all background images are in classes */
  h2 {
    color: $white;
    padding: 0 5%;
    font-weight: 200;
  }
  p {
    color: $white;
    font-weight: 200;
  }
}

.animation {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.wrapper {
  //width: 80%;
  //padding: 4%;
  //margin: 20px auto;
  background: #fff;
}

.wrapper.vl-cards {
  background: 0;
  //width: 88%;
  //padding: 20px 0 0 0;
}

.team-container {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  /* margin: 10px 0 10px 4%; */
  background: #fff;


  /* Set the depth of the elements */
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -o-perspective: 800px;
  perspective: 800px;
}

.team-container:first-child {
  margin-left: 0;
}

.vl-card {
  width: 100%;
  height: 100%;
  //position: absolute;
  cursor: pointer;
  max-width: 250px;

  /* Set the transition effects */
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  -o-transition: -o-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.vl-card.flipped, .vl-bio-text.flipped {
  -webkit-transform: rotateY( 180deg );
  -moz-transform: rotateY( 180deg );
  -o-transform: rotateY( 180deg );
  transform: rotateY( 180deg );
}

.vl-card .front,
.vl-card .back {
  display: block;
  height: 100%;
  width: 100%;
  //line-height: 260px;
  color: white;
  text-align: center;
  //font-size: 4em;
  //position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;

  //box-shadow: 3px 5px 20px 2px rgba(0, 0, 0, 0.25);
}

.vl-bio-text .front,
.vl-bio-text .back {
  display: block;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.vl-card .back {
  //width: 94%;
  //padding-left: 3%;
  //padding-right: 3%;
  //font-size: 16px;
  text-align: left;
  //line-height: 25px;

}

.formItem:first-child {
  //margin-top: 20px;
}

.vl-card .back label {
  display: inline-block;
  //width: 70px;
  text-align: left;
}

.vl-bio-text {
  position: relative;
  /* Set the transition effects */
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  -o-transition: -o-transform 0.4s;
  transition: transform 0.4s;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  h5 {
    font-size: 1rem;
    font-weight: bold;
  }
  .front {
    h5 {
      color: $vera-labs-red;
    }
    .back {
      h5 {
        color: $vera-labs-black;
      }
    }
  }
}

.vl-card .front {
  background: #222222;
  position: absolute;
}

.vl-bio-text .front {
  //position: absolute;
}

.vl-card .back {
  position: relative;
  background: #444444;
  -webkit-transform: rotateY( 180deg );
  -moz-transform: rotateY( 180deg );
  -o-transform: rotateY( 180deg );
  transform: rotateY( 180deg );
}

.vl-bio-text .back {
  position: relative;
  -webkit-transform: rotateY( 180deg );
  -moz-transform: rotateY( 180deg );
  -o-transform: rotateY( 180deg );
  transform: rotateY( 180deg );
}

.team-container:first-child .vl-card .front {
  background: #228653;
}

.team-container:first-child .vl-card .back {
  background: #007539;
}

.vl-cardTitle {
  font-size: 1.4em;
  line-height: 1.2em;
  margin: 0;
}

.content {
  //padding: 4%;
  font-weight: 100;
  text-align: left;
}

button.btnSend {
  display: inline-block;
  min-width: 100px;
  padding: 3px 5px;
  margin-top: 10px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: #03446A;
  background: #fff;
  border: 0;
  border-radius: 3;
}

.profile-button-wrapper {
  text-align: center;
  max-width: 250px;
  margin-bottom: 2rem;
}

.profile-button {
  border: 1px solid $vera-labs-black;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  &:hover {
    background-color: $vera-labs-light-gray;
    border: none;
  }
  .profile-button-professional {
    display: none;
  }
}

.bio-wrapper {
  margin-bottom: 3rem;
  h3 {
    margin-bottom: 0;
  }
  h4 {
    font-size: 1.4rem;
  }
  .vl-card-static {
    img {
      max-width: 250px;
    }
  }
}

#team-institutions {
  margin-bottom: 3rem;
  background-color: $vera-labs-light-gray;
  padding: 0 0 0 1rem;
  img {
    margin-right: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 200px;
  }
}

a.anchor {
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}

#layout-content {
  width: 100%;
}

#submit-wrapper-contactForm {
  text-align: center;
}

.required-field-indicator {
  color: red;
}

#meet-the-team-button-wrapper {
  width: 100%;
}

#products-pm-logo {
  max-width: 80%
}

.product-page-header-wrapper {
  margin-top:-175px;
  max-width:100%;
  max-height: 200px;
}

.product-page-logo {
  width: 100%;
  max-width: 500px;
}

.product-page-logo.product-page-veranote-logo {
  max-width: 120px;
}
